import { IMAGES } from "../../../../config";

export default function ListsMedia({ data, title, secondaryColor, isScale }) {
  return (
    <div className="media">
      <h2> {title} </h2>
      <div className="media-lists">
        {data.map((el) => (
          <img
            key={el.id}
            src={el.src}
            alt={el.id}
            style={{
              border: `1px solid ${
                el.id == 1 ? secondaryColor : "transparent"
              }`,
              transform: el.id == 1 && isScale ? `scale(1.05)` : `scale(0.99)`,
            }}
          />
        ))}
      </div>
    </div>
  );
}
