import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../store/features/profileSlice";

import { Menu, Modal } from "antd";

import useTranslate from "../hooks/translator";

import { ICONS } from "../config";

import { TooltipLongText } from "../components";
import SidebarFooter from "./components/SidebarFooter";
import MultiAccounts from "./MultiAccounts";
import Profile from "./Profile";

import "../styles/sidebar.scss";

export default function MenuContent({ onClose, isMobile }) {
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useTranslate();

  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profile);

  const [dashboardKey, setDashboardKey] = useState("");

  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard/series":
      case "/dashboard/lives":
      case "/dashboard/movies":
        return setDashboardKey("/dashboard");
      case "/dashboard/device":
        return setDashboardKey("/dashboard/devices");
      default:
        setDashboardKey(location.pathname);
        break;
    }
  }, [location.pathname]);

  function showBilling() {
    if (profile?.is_seller) {
      return true;
    }
    if (profile?.parent_id && profile?.status == "NEED_BUY_PARTNER_CREDITS") {
      return true;
    }
    return false;
  }

  const logout = () => {
    Modal.confirm({
      title: `${translate("Do you want to logout")} ?`,
      icon: "",
      okText: translate("Logout"),
      cancelText: translate("Cancel"),
      maskClosable: true,
      onOk: () => {
        setTimeout(() => {
          navigate("/login");
          localStorage.clear();
          dispatch(setProfile(null));
        }, 500);
      },
      okButtonProps: {
        className: "logout-button-classname",
      },
      cancelButtonProps: {
        className: "cancel-button-classname",
      },
    });
  };

  const items = [
    {
      key: "/dashboard",
      icon: <div className="sidebar-icon">{ICONS.DASHBOARD}</div>,
      label: (
        <TooltipLongText
          children={translate("Dashboard")}
          to="/dashboard"
          isMobile={isMobile}
        />
      ),
    },

    ((!profile?.parent_id && !profile?.is_partner) || showBilling()) && {
      key: "/dashboard/billing-page",
      icon: <div className="sidebar-icon">{ICONS.BILLING}</div>,
      label: (
        <TooltipLongText
          children={translate("Billing history")}
          to="/dashboard/billing-page"
          isMobile={isMobile}
        />
      ),
    },

    (!profile?.parent_id ||
      (profile?.parent_id &&
        profile?.status === "NEED_BUY_PARTNER_CREDITS")) && {
      key: "/dashboard/server-hosts",
      icon: <div className="sidebar-icon">{ICONS.SERVER}</div>,
      label: (
        <TooltipLongText
          children={translate("DNS")}
          to="/dashboard/server-hosts"
          isMobile={isMobile}
        />
      ),
    },
    {
      key: "/dashboard/devices",
      icon: <div className="sidebar-icon">{ICONS.DEVICES}</div>,
      label: (
        <TooltipLongText
          children={translate("Devices")}
          to="/dashboard/devices"
          isMobile={isMobile}
        />
      ),
    },

    (profile?.is_partner || (profile?.parent_id && !profile?.is_partner)) && {
      key: "/dashboard/subresellers",
      icon: <div className="sidebar-icon">{ICONS.SUB_RESELLERS}</div>,
      label: (
        <TooltipLongText
          children={translate("Subresellers")}
          to="/dashboard/subresellers"
          isMobile={isMobile}
        />
      ),
    },

    {
      key: "/dashboard/branding-page",
      icon: <div className="sidebar-icon">{ICONS.BRANDING}</div>,
      label: (
        <TooltipLongText
          children={translate("Branding")}
          to="/dashboard/branding-page"
          isMobile={isMobile}
        />
      ),
    },

    // profile?.is_partner &&
    //   profile?.app_name && {
    //     key: "/dashboard/banner-ad",
    //     icon: <div className="sidebar-icon">{ICONS.BANNER_AD}</div>,
    //     label: (
    //       <TooltipLongText
    //         children={translate("Banner AD")}
    //         to="/dashboard/banner-ad"
    //         isMobile={isMobile}
    //       />
    //     ),
    //   },

    // {
    //   key: "/dashboard/notification-page",
    //   icon: <div className="sidebar-icon">{ICONS.NOTIFICATION}</div>,
    //   label: (
    //   <TooltipLongText children={translate("Notifications")} to="/dashboard/notification-page"  isMobile={isMobile}/>,
    //   ),
    // },

    {
      key: "/dashboard/error-logs",
      icon: <div className="sidebar-icon">{ICONS.BUG}</div>,
      label: (
        <TooltipLongText
          children={translate("Error logs")}
          to="/dashboard/error-logs"
          isMobile={isMobile}
        />
      ),
    },

    {
      key: "/dashboard/settings",
      icon: <div className="sidebar-icon">{ICONS.CONFIG}</div>,
      label: (
        <TooltipLongText
          children={translate("Settings")}
          to="/dashboard/settings"
          isMobile={isMobile}
        />
      ),
    },

    {
      icon: <div className="sidebar-icon log-out">{ICONS.LOG_OUT}</div>,
      key: "logout",
      label: (
        <div className="log-out" onClick={logout}>
          {translate("Logout")}
        </div>
      ),
    },
  ];

  return (
    <div className="accounts-sidebar">
      <MultiAccounts />
      <div className="menu-content">
        <div className="logo-block">
          <Profile onClose={onClose} />
        </div>

        <div className="sidebar-wrap">
          {profile && (
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={["/dashboard"]}
              selectedKeys={dashboardKey}
              onSelect={() => {
                if (onClose) onClose();
              }}
              items={items}
            />
          )}
        </div>

        <SidebarFooter />
      </div>
    </div>
  );
}
