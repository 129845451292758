import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../store/features/profileSlice";

import { Button } from "antd";

import useTranslate from "../hooks/translator";

import { ICONS, PATHS } from "../config";
import IMAGES from "../config/images";

import EditProfileDrawer from "./EditProfileDrawer";
import TokenRefresh from "./TokenRefresh";

import "../styles/header.scss";

let isFeildRefreshTimeout = null;

export default function Profile({ onClose }) {
  const navigate = useNavigate();
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profile);

  const [visibleProfileDrawer, setVisibleProfileDrawer] = useState(false);

  const [isOpenRefrashTokenModal, setIsOpenRefrashTokenModal] = useState(false);

  const tokenRefresh = JSON.parse(localStorage.getItem("refresh_token"));

  useEffect(() => {
    const leftTime = new Date(tokenRefresh?.start_time + 3480000) - new Date(); //780000
    const feildTime = new Date(tokenRefresh?.start_time + 3600000) - new Date(); //900000

    const timeout = setTimeout(() => {
      setIsOpenRefrashTokenModal(true);
    }, leftTime);

    isFeildRefreshTimeout = setTimeout(() => {
      localStorage.clear();
      dispatch(setProfile(null));
      navigate(PATHS.LOGIN);
    }, feildTime);

    return () => {
      clearTimeout(timeout);
      clearTimeout(isFeildRefreshTimeout);
    };
  }, [profile, tokenRefresh]);

  useEffect(() => {
    clearTimeout(isFeildRefreshTimeout);
  }, []);

  const showDrawer = (e) => {
    e.stopPropagation();
    // if (onClose) onClose();
    setVisibleProfileDrawer(true);
  };

  return (
    <>
      <div className="profile-block">
        <div className="profile-image-container">
          <img
            src={profile?.app_branding?.logo || IMAGES.LOGO}
            onError={(e) => (e.target.src = IMAGES.LOGO)}
            alt="profile"
          />
        </div>

        <div className="profile-text">
          <p className="name-surname">
            {profile?.name} {profile?.surname}
          </p>
          <p style={{ margin: "3px 0" }}>{profile && profile?.brand_name}</p>
          <Button
            type="link"
            icon={ICONS.EDIT}
            onClick={showDrawer}
            className="edit-profile"
          >
            {translate("Edit profile")}
          </Button>
        </div>
      </div>

      <EditProfileDrawer
        visibleProfileDrawer={visibleProfileDrawer}
        onCloseEditProfileDrawer={() => setVisibleProfileDrawer(false)}
      />

      <TokenRefresh
        open={isOpenRefrashTokenModal}
        onSuccess={() => clearTimeout(isFeildRefreshTimeout)}
        onCancel={() => setIsOpenRefrashTokenModal(false)}
      />
    </>
  );
}
