import { top_movies, top_series } from "../../../config/data";
import MenuButtons from "./components/MenuButtons";
import ListsMedia from "./components/ListsMedia";

import "./styles/menu-page-two.scss";

export default function MenuPageTwo({
  logo,
  logoSize,
  background,
  primaryColor,
  secondaryColor,
}) {
  return (
    <div
      className="menu-page-two"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="menu-page-wrap">
        <div className="left-section">
          <div className="logo">
            <img
              src={logo}
              alt="logo"
              style={{ width: logoSize ? `${logoSize * 5}px` : "50px" }}
            />
          </div>
          <MenuButtons primaryColor={primaryColor} />
        </div>
        <div className="right-section">
          <ListsMedia
            data={top_movies}
            title="10 Recently Added Movies"
            secondaryColor={secondaryColor}
            isScale={true}
          />
          <ListsMedia
            data={top_series}
            title="10 Recently Added Series"
            secondaryColor="transparent"
          />
        </div>
      </div>
      <div className="menu-page-two-tv-footer">
        <p>
          <span> Device Key: 3GK689</span>
        </p>
        <p>App version: 1.1.5</p>
      </div>
    </div>
  );
}
